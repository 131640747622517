import { FunctionComponent, Ref, useMemo } from 'react'
import styled, { css, DefaultTheme, useTheme } from 'styled-components'
import {
  CommonCTAProps,
  ButtonProps,
  commonCTAStyles,
  TextWithIconWrapper,
} from './CommonCTA'

type PrimaryCTAButtonColorVariant = 'red' | 'green' | 'white' | 'black' | 'grey'

export type PrimaryCTAButtonProps = CommonCTAProps &
  ButtonProps & {
    colorVariant?: PrimaryCTAButtonColorVariant
    ref?: Ref<HTMLButtonElement>
  }

export const getColorVariantsMapForPrimaryCTAButton = (
  themeColor: DefaultTheme['color']
): Record<
  PrimaryCTAButtonColorVariant,
  {
    background: {
      default: string
      hover: string
      focus: string
      disabled: string
    }
    text: {
      default: string
      disabled: string
    }
  }
> => ({
  red: {
    background: {
      default: themeColor.primary.blickRed,
      hover: themeColor.secondary.darkRed,
      focus: themeColor.secondary.darkRed,
      disabled: themeColor.tertiary.grey200,
    },
    text: {
      default: themeColor.primary.primary02,
      disabled: themeColor.tertiary.grey400,
    },
  },
  green: {
    background: {
      default: themeColor.secondary.greenAccessible,
      hover: themeColor.secondary.darkGreen,
      focus: themeColor.secondary.darkGreen,
      disabled: themeColor.tertiary.grey200,
    },
    text: {
      default: themeColor.primary.primary02,
      disabled: themeColor.tertiary.grey400,
    },
  },
  white: {
    background: {
      default: themeColor.primary.primary02,
      hover: themeColor.tertiary.grey100,
      focus: themeColor.tertiary.grey100,
      disabled: themeColor.tertiary.grey200,
    },
    text: {
      default: themeColor.primary.primary01,
      disabled: themeColor.tertiary.grey400,
    },
  },
  black: {
    background: {
      default: themeColor.primary.primary01,
      hover: themeColor.tertiary.grey1000,
      focus: themeColor.tertiary.grey1000,
      disabled: themeColor.tertiary.grey200,
    },
    text: {
      default: themeColor.primary.primary02,
      disabled: themeColor.tertiary.grey400,
    },
  },
  grey: {
    background: {
      default: themeColor.tertiary.grey300,
      hover: themeColor.tertiary.grey400,
      focus: themeColor.tertiary.grey400,
      disabled: themeColor.tertiary.grey200,
    },
    text: {
      default: themeColor.primary.primary01,
      disabled: themeColor.tertiary.grey400,
    },
  },
})

export const primaryCTAButtonStyles = css<StyledPrimaryCTAButtonType>`
  ${({ buttonStateColors, disabled }) => css`
    background-color: ${buttonStateColors.background.default};
    color: ${buttonStateColors.text.default};

    & > svg {
      color: ${buttonStateColors.text.default};
      fill: ${buttonStateColors.text.default};
    }

    @media (hover: hover) {
      &:hover:not([disabled]) {
        background-color: ${buttonStateColors.background.hover};
      }
    }

    &:focus-visible {
      background-color: ${buttonStateColors.background.focus};
    }

    ${disabled &&
    css`
      pointer-events: none;
      background-color: ${buttonStateColors.background.disabled};
      color: ${buttonStateColors.text.disabled};

      & > svg {
        color: ${buttonStateColors.text.disabled};
        fill: ${buttonStateColors.text.disabled};
      }
    `};
  `}
`

export type StyledPrimaryCTAButtonType = Required<
  Pick<PrimaryCTAButtonProps, 'size' | 'iconLeft' | 'disabled'>
> & {
  buttonStateColors: ReturnType<
    typeof getColorVariantsMapForPrimaryCTAButton
  >[PrimaryCTAButtonColorVariant]
}

const StyledPrimaryCTAButton = styled.button<StyledPrimaryCTAButtonType>`
  ${commonCTAStyles}
  ${primaryCTAButtonStyles}
`

const PrimaryCTAButton: FunctionComponent<PrimaryCTAButtonProps> = ({
  colorVariant = 'red',
  size = 'large',
  type = 'button',
  disabled = false,
  iconName,
  iconLeft = false,
  className,
  children,
  onClick,
  ref = null,
}) => {
  const theme = useTheme()

  const buttonStateColors = useMemo(
    () => getColorVariantsMapForPrimaryCTAButton(theme.color)[colorVariant],
    [theme.color, colorVariant]
  )

  return (
    <StyledPrimaryCTAButton
      ref={ref}
      className={className}
      size={size}
      disabled={disabled}
      type={type}
      onClick={onClick}
      buttonStateColors={buttonStateColors}
      iconLeft={iconLeft}>
      {iconName ? (
        <TextWithIconWrapper iconName={iconName} size={size}>
          {children}
        </TextWithIconWrapper>
      ) : (
        children
      )}
    </StyledPrimaryCTAButton>
  )
}

export default PrimaryCTAButton
